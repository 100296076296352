exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-cara/src/templates/cara.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-gdpr-privacy-tsx": () => import("./../../../src/pages/en/gdpr-privacy.tsx" /* webpackChunkName: "component---src-pages-en-gdpr-privacy-tsx" */),
  "component---src-pages-en-privacy-tsx": () => import("./../../../src/pages/en/privacy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-ir-tsx": () => import("./../../../src/pages/ir.tsx" /* webpackChunkName: "component---src-pages-ir-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

